








import { i18n } from '@/i18n';
import Loading from '@/shared/components/Loading.vue';
import { ConfigService } from '@/shared/services/config.service';
import {
  FilterConditions,
  FilterInput,
  TransformFiltersToJson
} from '@/shared/services/filter/filter.service';
import { ToastHelper } from '@/utils/toast.util';
import { getLanguageCode } from '@/utils/utils';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: { Loading }
})
export default class MapPage extends Vue {
  // variables
  loading = true;
  isframe = false;
  frameUrl = null;

  idFilter = new FilterInput({
    condition: FilterConditions.BeginsWith,
    search: 'FMS_ACCT_ENTERPRISE',
    field: 'id'
  });

  // constructor
  constructor() {
    super();
  }

  // methods
  created() {}

  mounted() {
    this.loadMap();
  }

  async loadMap() {
    try {
      this.loading = true;
      await ConfigService.getConfig(
        TransformFiltersToJson([this.idFilter])
      ).then(async result => {
        const data = {
          userId: result.data.find(a => a.id === 'FMS_ACCT_ENTERPRISE_USERNAME')
            ?.value,
          password: result.data.find(
            a => a.id === 'FMS_ACCT_ENTERPRISE_PASSWORD'
          )?.value
        };
        this.frameUrl =
          `${process.env.VUE_APP_FLEETUP_MAP_URL}/login_tms.html?key=${btoa(
            'userId=' + data.userId + '&' + 'password=' + data.password
          )}&to=enterprise.map&lang=` + getLanguageCode(i18n.locale);

        this.isframe = true;
      });
    } catch (error) {
      console.log(error);
      ToastHelper.show(
        `${this.$route.params.type}/${this.$route.name.replace(
          `${this.$route.params.type}-`,
          ''
        )}`,
        error.message,
        5000,
        'danger'
      );
      this.isframe = false;
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 5000);
    }
  }
}
